import FileCopyIcon from '@mui/icons-material/FileCopy';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  debounce,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Iconify from '../../../components/Iconify';
import LocationSelector from '../../../components/LocationSelector';
import NumberInput from '../../../components/NumberInput';
import Page from '../../../components/Page';
import { CLIENT_PORT, ENV } from '../../../config';
import useLocales from '../../../hooks/useLocales';
import useSettings from '../../../hooks/useSettings';
import * as contactActions from '../../../models/contacts/actions';
import { selectors as contactSelectors } from '../../../models/contacts/reducers';
import * as propertiesActions from '../../../models/properties/actions';
import { selectors as propertiesSelectors } from '../../../models/properties/reducers';
import { selectors as userSelectors } from '../../../models/user/reducers';
import CoverPictureSelector from './CoverPictureSelector';
import PropertyImagesSelector from './ListingsImagesSelector';

const commonFieldsProps = {
  InputLabelProps: {
    shrink: true,
  },
};

const serializeOption = (opt) => `${opt.name} - ${opt.mobile_number}`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 3,
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const ListingsForm = ({ formik, filters }) => {
  const { currentLang } = useLocales();
  const { themeStretch } = useSettings();
  const { themeDirection } = useSettings();

  const [markerPosition, setMarkerPosition] = useState(null);
  const [tabIdx, setTabIdx] = useState(0);
  const [copied, setCopied] = useState(false);

  const handleTabChange = (evt, value) => {
    setTabIdx(value);
  };

  const dispatch = useDispatch();
  const search = useSelector(contactSelectors.search);
  const searchLoading = useSelector(contactSelectors.loading);
  const amenities = useSelector(propertiesSelectors.amenities);
  const workspace = useSelector(userSelectors.workspace);

  const handleSearch = debounce((e) => {
    const input = e.target.value;
    dispatch(contactActions.searchContactByNameRequest({ input, filters }));
  }, 500);

  const {
    translate,
    currentLang: { value: languageCode },
  } = useLocales();
  useEffect(() => {
    if (formik.values.latitude && formik.values.longitude) {
      setMarkerPosition({
        lat: parseFloat(formik.values.latitude),
        lng: parseFloat(formik.values.longitude),
      });
    }
  }, [formik.values.longitude, formik.values.latitude]);

  useEffect(() => {
    dispatch(propertiesActions.getAmenitiesRequest());
  }, [dispatch]);

  const numberInputFeatures = [
    {
      name: 'king_beds',
      label: 'unit.kingBeds',
    },
    {
      name: 'single_beds',
      label: 'unit.singleBeds',
    },
    {
      name: 'number_of_guests',
      label: 'unit.numberOfGuests',
    },
    {
      name: 'number_of_kids',
      label: 'unit.numberOfKids',
    },
    {
      name: 'bedrooms',
      label: 'unit.bedrooms',
    },
    {
      name: 'bathrooms',
      label: 'unit.bathrooms',
    },
    {
      name: 'number_of_floors',
      label: 'unit.floors',
    },
    {
      name: 'unit_floor_number',
      label: 'unit.floorNumber',
    },
    {
      name: 'living_rooms',
      label: 'unit.dailyLivingRoom',
    },
    {
      name: 'dining_rooms',
      label: 'unit.diningRooms',
    },
    {
      name: 'maid_rooms',
      label: 'unit.maidRooms',
    },
    {
      name: 'driver_rooms',
      label: 'unit.driverRooms',
    },
    {
      name: 'majlis_rooms',
      label: 'unit.majlisRooms',
    },
    {
      name: 'storage_rooms',
      label: 'unit.storageRooms',
    },
    {
      name: 'basement_rooms',
      label: 'unit.basement',
    },
    { name: 'pools', label: 'unit.pool' },
    {
      name: 'balconies',
      label: 'unit.balconies',
    },
    {
      name: 'kitchens',
      label: 'unit.kitchens',
    },
    {
      name: 'gardens',
      label: 'unit.gardens',
    },
    {
      name: 'mulhaq_rooms',
      label: 'unit.mulhaqRooms',
    },
    {
      name: 'elevators',
      label: 'unit.elevators',
    },
    {
      name: 'parking_spots',
      label: 'unit.parkingBay',
    },
  ];

  const handleChangeLocation = ({ districtId }) => {
    formik.setFieldValue('district_id', districtId);
  };

  const updateLatLng = ({ lat, lng }) => {
    formik.setFieldValue('latitude', lat);
    formik.setFieldValue('longitude', lng);

    setMarkerPosition({
      lat,
      lng,
    });
  };

  const handleMapClick = (mapEvent) => {
    updateLatLng(mapEvent);
  };

  const onPlaceChanged = (autoComplete) => {
    const place = autoComplete.getPlace();
    const { lat, lng } = place.geometry.location;
    updateLatLng({ lat: lat(), lng: lng() });
  };

  const startYear = new Date().getFullYear() - 100;
  const currentYear = new Date().getFullYear();

  const yearsArray = [];
  // eslint-disable-next-line for-direction
  for (let year = currentYear; year >= startYear; year -= 1) {
    yearsArray.push(year);
  }

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const amenityId = parseInt(name, 10);

    if (checked) {
      formik.setFieldValue('amenities', [...formik.values.amenities, amenityId]);
    } else {
      formik.setFieldValue(
        'amenities',
        formik.values.amenities.filter((id) => id !== amenityId)
      );
    }
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(url);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const yearBuilt = formik.values.year_built.toString() === '0' ? '' : formik.values.year_built;

  const protocol = ENV === 'local' ? 'http' : 'https';
  const clientDomain = workspace?.client_domain;
  let url = '';

  if (ENV === 'local') {
    url = `${protocol}://${clientDomain}${CLIENT_PORT}/reservations/${formik.values.id}`;
  } else {
    url = `${protocol}://${clientDomain}/reservations/${formik.values.id}`;
  }

  return (
    <Page title={translate('units')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box
          sx={{
            '& div.MuiBox-root': {
              p: 0,
            },
          }}
        >
          {/* <Tabs value={tabIdx} onChange={handleTabChange}>
            <Tab label={translate('member.members')} {...a11yProps(0)} />
            <Tab label={translate('member.invitations')} {...a11yProps(1)} />
          </Tabs> */}

          <TabPanel value={tabIdx} index={0}>
            <form onSubmit={formik.handleSubmit}>
              <button type="submit" style={{ display: 'none' }}>
                Submit
              </button>
              <Grid container spacing={2} justifyContent={'center'}>
                <Grid item xs={11} lg={8}>
                  <Grid container spacing={2}>
                    {/* facts */}
                    <Grid item xs={12}>
                      <Card>
                        <CardContent>
                          <Typography variant="h6" paragraph sx={{ mb: 4 }}>
                            {translate('unit.facts')}
                          </Typography>

                          <Box sx={{ pt: 3, pb: 3, mt: 2 }}>
                            <Grid container spacing={4}>
                              <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                  {...commonFieldsProps}
                                  name="type"
                                  label={translate('unit.listingType')}
                                  value={translate(`unit.${formik.values.type}`)}
                                  disabled
                                  fullWidth
                                />
                              </Grid>

                              <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                  {...commonFieldsProps}
                                  name="category"
                                  label={translate('unit.category')}
                                  value={translate(`unit.${formik.values.category}`)}
                                  disabled
                                  fullWidth
                                />
                              </Grid>

                              <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                  {...commonFieldsProps}
                                  name="purpose"
                                  label={translate('unit.purpose')}
                                  value={translate(`unit.${formik.values.purpose}`)}
                                  disabled
                                  fullWidth
                                />
                              </Grid>

                              <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                  {...commonFieldsProps}
                                  name="unit_number"
                                  label={translate('unit.unit_number')}
                                  value={formik.values.unit_number}
                                  fullWidth
                                  onChange={formik.handleChange}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                    {/* facts */}

                    {/* rent */}
                    {formik.values.purpose === 'rent' && (
                      <Grid item xs={12}>
                        <Card>
                          <CardContent>
                            <Typography variant="h6" paragraph sx={{ mb: 4 }}>
                              {translate('unit.rentingPrice')}
                            </Typography>
                            <Box sx={{ pt: 3, pb: 3, mt: 2 }}>
                              <Grid container spacing={2} rowSpacing={6}>
                                <Grid item xs={12} sm={6} md={4}>
                                  <NumberInput
                                    name="rent_price_daily"
                                    label={translate('siyaha.unit.dailyRatePrice')}
                                    showControls={false}
                                    formik={formik}
                                    fullWidth
                                    helperText={translate('Saudi Riyals')}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <Card sx={{ marginBottom: 2 }}>
                        <CardContent>
                          <Typography variant="h6" paragraph sx={{ mb: 4 }}>
                            {translate('unit.features')}
                          </Typography>

                          <Box sx={{ pt: 3, pb: 3, mt: 2 }}>
                            <Grid container spacing={2} rowSpacing={6}>
                              <Grid item xs={12} sm={6} md={4}>
                                <NumberInput
                                  name="area"
                                  label={translate('unit.area')}
                                  showControls={false}
                                  fullWidth
                                  formik={formik}
                                  helperText={translate('sqm')}
                                />
                              </Grid>

                              <Grid item xs={12} sm={6} md={4}>
                                <FormControl fullWidth>
                                  <InputLabel id="unit-year-built">{translate('unit.yearBuilt')}</InputLabel>
                                  <Select
                                    name="year_built"
                                    label={translate('unit.yearBuilt')}
                                    labelId="unit-year-built"
                                    value={yearBuilt}
                                    error={formik.touched.year_built && formik.errors.year_built}
                                    onChange={formik.handleChange}
                                  >
                                    {yearsArray.map((year) => (
                                      <MenuItem key={year} value={year}>
                                        {year}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                            </Grid>

                            <Grid container spacing={2} rowSpacing={6} sx={{ mt: 1 }}>
                              {['building', 'mansion', 'duplex', 'townhouse', 'villa'].find(
                                (t) => t === formik.values.type
                              ) && (
                                <Grid item xs={12} sm={6} md={3}>
                                  <NumberInput
                                    name="apartments"
                                    label={translate('unit.apartments')}
                                    formik={formik}
                                    showControls
                                    positive
                                  />
                                </Grid>
                              )}

                              {[
                                'tower_apartment',
                                'mansion',
                                'duplex',
                                'building_apartment',
                                'townhouse',
                                'villa',
                                'villa_apartment',
                                'villa_floor',
                              ].find((t) => t === formik.values.type) &&
                                numberInputFeatures.map((props, i) => (
                                  <Grid item xs={12} sm={6} md={3} key={i}>
                                    <NumberInput fullWidth showControls positive formik={formik} {...props} />
                                  </Grid>
                                ))}
                            </Grid>
                          </Box>
                        </CardContent>
                      </Card>

                      {amenities &&
                        amenities.map((section) => (
                          <Grid item xs={12} key={section.category_en} sx={{ paddingY: 1 }}>
                            <Card>
                              <CardContent>
                                <Typography variant="h6" paragraph sx={{ mb: 2 }}>
                                  {themeDirection === 'rtl' ? section.category_ar : section.category_en}
                                </Typography>
                                <Grid container spacing={2}>
                                  {section.items.map((item, i) => (
                                    <Grid item xs={12} sm={3} key={i}>
                                      <FormControlLabel
                                        label={themeDirection === 'rtl' ? item.amenity_name_ar : item.amenity_name_en}
                                        control={
                                          <Checkbox
                                            name={item.id.toString()}
                                            checked={formik.values.amenities.includes(item.id)}
                                            onChange={handleCheckboxChange}
                                          />
                                        }
                                      />
                                    </Grid>
                                  ))}
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={11} lg={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Card>
                        <CardContent>
                          <Typography variant="h6" paragraph sx={{ mb: 4 }}>
                            {translate('unit.publishedStatus')}
                          </Typography>

                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <FormControlLabel
                                label={translate('unit.publishedOnWebsite')}
                                control={
                                  <Checkbox name="published_on_website" checked={formik.values.published_on_website} />
                                }
                                onChange={formik.handleChange}
                              />
                            </Grid>
                            {formik.initialValues.published_on_website && formik.values.id && (
                              <Grid item xs={12}>
                                {workspace?.client_domain && (
                                  <Box>
                                    <Typography variant="subtitle2" paragraph>
                                      {translate('unit.link')}
                                    </Typography>
                                    <Box>
                                      <Button
                                        component="a"
                                        href={url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        startIcon={<Iconify icon="icon-park-outline:share" />}
                                        sx={{ fontSize: '0.75rem' }}
                                        color="secondary"
                                      >
                                        {translate('unit.navToPropertyPage')}
                                      </Button>
                                      <Tooltip
                                        title={copied ? translate('unit.copiedUrl') : translate('unit.copyUrl')}
                                        onClose={() => setCopied(false)}
                                      >
                                        <Button
                                          onClick={handleCopyClick}
                                          startIcon={<FileCopyIcon />}
                                          sx={{ fontSize: '0.75rem' }}
                                          color="secondary"
                                        >
                                          {translate('unit.copyUrl')}
                                        </Button>
                                      </Tooltip>
                                    </Box>
                                  </Box>
                                )}
                              </Grid>
                            )}
                            {/* <Grid item xs={12}>
                      <FormControlLabel
                        label={translate('unit.publishedOnNuzulApp')}
                        control={<Checkbox name="published_on_app" checked={formik.values.published_on_app} />}
                        onChange={formik.handleChange}
                      />
                    </Grid> */}
                            <Grid item xs={12}>
                              <TextField
                                {...commonFieldsProps}
                                name="sort"
                                label={translate('unit.sort')}
                                value={formik.values.sort}
                                fullWidth
                                onChange={formik.handleChange}
                                helperText={translate('unit.sortHelperText')}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid item xs={12}>
                      <CoverPictureSelector formik={formik} />
                    </Grid>

                    <Grid item xs={12}>
                      <Card>
                        <CardContent>
                          <Typography variant="h6" paragraph sx={{ mb: 4 }}>
                            {translate('unit.photos')}
                          </Typography>
                          <PropertyImagesSelector formik={formik} />
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid item xs={12}>
                      <Card>
                        <CardContent>
                          <Typography variant="h6" paragraph sx={{ mb: 4 }}>
                            {translate('unit.location')}
                          </Typography>

                          <Box>
                            <LocationSelector
                              formik={formik}
                              onLocation={handleChangeLocation}
                              onMapClick={handleMapClick}
                              markerPosition={markerPosition}
                              onPlaceChanged={onPlaceChanged}
                            />
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </TabPanel>

          {/* <TabPanel value={tabIdx} index={1}>
            <form>
              <Box>hey</Box>
            </form>
          </TabPanel> */}
        </Box>
      </Container>
    </Page>
  );
};

export default ListingsForm;
