import { Box, Button } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import InfoBox from '../../../components/InfoBox';
import Page from '../../../components/Page';
import useLocales from '../../../hooks/useLocales';
import * as notificationsActions from '../../../models/notifications/actions';
import { selectors as notificationsSelectors } from '../../../models/notifications/reducers';
import {
  findPropertyReservationNotification,
  hasPropertyReservationNotification,
  isNotificationManuallyControlled,
} from '../../../models/notifications/utils';
import * as reservationActions from '../../../models/t-reservations/actions';
import { selectors as reservationSelectors } from '../../../models/t-reservations/reducers';
import reservationServices from '../../../models/t-reservations/services';
import ReservationsForm from './ReservationsForm';

const EditReservations = () => {
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const { id } = useParams();
  const notifications = useSelector(notificationsSelectors.notifications);

  useEffect(() => {
    dispatch(reservationActions.getReservationDetailsRequest(id));
  }, [id]);

  useEffect(() => {
    const isUnseen = hasPropertyReservationNotification(id, notifications || []);
    const notification = findPropertyReservationNotification(id, notifications || []);

    if (notification && isUnseen && !isNotificationManuallyControlled(notification)) {
      dispatch(
        notificationsActions.markAsReadRequest({
          id: notification.id,
        })
      );
    }
  }, [id, notifications]);

  const handleConfirm = () => {
    reservationServices
      .confirmReservation({ id, values: {} })
      .then(() => {
        toast.success(translate('siyaha.reservation.confirmSuccess'));
        dispatch(reservationActions.getReservationDetailsRequest(id));
      })
      .catch(() => {
        toast.error(translate('siyaha.reservation.confirmError'));
      });
  };

  const handleCancel = () => {
    reservationServices
      .cancelReservation({ id, values: {} })
      .then(() => {
        toast.success(translate('siyaha.reservation.cancelSuccess'));
        dispatch(reservationActions.getReservationDetailsRequest(id));
      })
      .catch(() => {
        toast.error(translate('siyaha.reservation.cancelError'));
      });
  };

  const handleConfirmPayment = () => {};

  const reservationDetails = useSelector(reservationSelectors.reservationDetails);
  const getReservationDetailsLoading = useSelector(reservationSelectors.getReservationDetailsLoading);

  const formik = useFormik({
    initialValues: {
      id: reservationDetails?.id,
      check_in_date: reservationDetails?.check_in_date,
      check_out_date: reservationDetails?.check_out_date,
      property: reservationDetails?.property,
      status: reservationDetails?.status,
      tenant_contact: reservationDetails?.tenant_contact,
      order: reservationDetails?.order,
    },
    isInitialValid: false,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log('reservation:', values);
    },
  });

  const createdAt = reservationDetails?.created_at || new Date();

  return (
    <Page title={translate('siyaha.reservation.reservationDetails')}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
        <InfoBox
          label={translate('siyaha.reservation.reservationDetails')}
          number={reservationDetails?.id}
          date={createdAt}
          backButtonLink="/siyaha/reservations"
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'end',
              height: '100%',
              justifyContent: 'start',
            }}
          >
            {reservationDetails?.status !== 'canceled' && (
              <Button
                onClick={() => handleCancel()}
                variant="outlined"
                sx={{
                  color: 'error.main',
                  borderColor: 'error.main',
                  marginLeft: 2,
                  '&:hover': {
                    color: 'error.dark',
                    borderColor: 'error.dark',
                    backgroundColor: 'transparent',
                  },
                }}
              >
                {translate('siyaha.reservation.cancelButton')}
              </Button>
            )}

            {(reservationDetails?.status === 'on_hold' || reservationDetails?.status === 'pending') && (
              <Button onClick={() => handleConfirm()} variant="contained" sx={{ color: 'white', marginLeft: 2 }}>
                {/* {!true && translate('unit.saveButton')}
              {false && <Loader />} */}
                {translate('siyaha.reservation.confirmButton')}
              </Button>
            )}
          </Box>
        </InfoBox>
      </Box>
      <ReservationsForm formik={formik} isLoading={getReservationDetailsLoading} />
    </Page>
  );
};

export default EditReservations;
