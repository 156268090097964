import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

const initialState = {
  tOrders: null,
  getTOrdersLoading: false,
  tOrderDetails: null,
  getTOrderDetailsLoading: false,
};

const reducer = createReducer(initialState, {
  [actions.getOrdersRequest]: (state) => {
    state.getTOrdersLoading = true;
  },
  [actions.getOrdersSuccess]: (state, action) => {
    state.getTOrdersLoading = false;
    state.tOrders = action.payload.data;
  },
  [actions.getOrdersFailure]: (state) => {
    state.getTOrdersLoading = false;
  },

  [actions.getOrderDetailsRequest]: (state) => {
    state.getTOrdersLoading = true;
  },
  [actions.getOrderDetailsSuccess]: (state, action) => {
    state.getTOrdersLoading = false;
    state.tOrderDetails = action.payload.data;
  },
  [actions.getOrderDetailsFailure]: (state) => {
    state.getTOrdersLoading = false;
  },
});

const getTOrdersLoading = (state) => state.tOrders.getTOrdersLoading;
const tOrders = (state) => state.tOrders.tOrders;
const tOrderDetails = (state) => state.tOrders.tOrderDetails;
const getTOrderDetailsLoading = (state) => state.tOrders.getTOrderDetailsLoading;

const selectors = {
  tOrders,
  getTOrdersLoading,
  tOrderDetails,
  getTOrderDetailsLoading,
};

export { initialState, reducer, selectors };
export default reducer;
