import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
// eslint-disable-next-line import/no-named-as-default
import ProjectsServices from './services';

export function* getProjectsSaga({ payload }) {
  try {
    const response = yield call(ProjectsServices.getProjects, payload);
    yield put(actions.getProjectsSuccess(response.data));
  } catch (err) {
    yield put(actions.getProjectsFailure(err));
  }
}

export function* searchProjectsSaga({ payload }) {
  try {
    const response = yield call(ProjectsServices.searchProjects, payload);
    yield put(actions.searchProjectsSuccess(response.data));
  } catch (err) {
    yield put(actions.searchProjectsFailure(err));
  }
}

export function* filterProjectsSaga({ payload }) {
  try {
    const response = yield call(ProjectsServices.filterProjects, payload);
    yield put(actions.filterProjectsSuccess(response.data));
  } catch (err) {
    yield put(actions.filterProjectsFailure(err));
  }
}

export function* getProjectSaga({ payload }) {
  try {
    const response = yield call(ProjectsServices.getProject, payload);
    yield put(actions.getProjectSuccess(response.data));
  } catch (err) {
    yield put(actions.getProjectFailure(err));
  }
}

export function* createProjectSaga({ payload }) {
  const { callback, ...values } = payload;

  try {
    const response = yield call(ProjectsServices.createProject, values);
    yield put(actions.createProjectSuccess(response.data));
    console.log(response);
    callback?.('success', response.data);
  } catch (err) {
    yield put(actions.createProjectFailure(err));
    callback?.('error', err);
  }
}

export function* editProjectSaga({ payload }) {
  const { id, callback, ...values } = payload;

  try {
    const response = yield call(() => ProjectsServices.editProject(id, values));
    yield put(actions.editProjectSuccess(response.data));
    callback?.('success', response.data);
  } catch (err) {
    yield put(actions.editProjectFailure(err));
    callback?.('error', err);
  }
}

export function* toggleProjectArchiveSaga({ payload }) {
  const { id, callback } = payload;

  try {
    const response = yield call(() => ProjectsServices.toggleProjectAchieve(id));
    yield put(actions.toggleArchiveProjectSuccess(response.data));
    callback?.('success', response.data);
  } catch (err) {
    yield put(actions.toggleArchiveProjectFailure(err));
    callback?.('error', err);
  }
}

export function* projectsSaga() {
  yield all([
    takeLatest(actions.getProjectsRequest, getProjectsSaga),
    takeLatest(actions.searchProjectsRequest, searchProjectsSaga),
    takeLatest(actions.filterProjectsRequest, filterProjectsSaga),
    takeLatest(actions.createProjectRequest, createProjectSaga),
    takeLatest(actions.getProjectRequest, getProjectSaga),
    takeLatest(actions.editProjectRequest, editProjectSaga),
    takeLatest(actions.toggleArchiveProjectRequest, toggleProjectArchiveSaga),
  ]);
}
