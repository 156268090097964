import 'react-toastify/dist/ReactToastify.css';

import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { ProgressBarStyle } from './components/ProgressBar';
import ScrollToTop from './components/ScrollToTop';
// components
import ThemeSettings from './components/settings';
import useLocales from './hooks/useLocales';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
import { setPreferredLanguageHeader } from './utils/axios';

// ----------------------------------------------------------------------

export default function App() {
  const { currentLang } = useLocales();

  useEffect(() => {
    setPreferredLanguageHeader(currentLang.value);
  }, [currentLang]);

  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <ProgressBarStyle />
          <ScrollToTop />
          <ToastContainer
            position={currentLang.value === 'ar' ? 'top-left' : 'top-right'}
            autoClose={5000}
            newestOnTop
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
            bodyStyle={{
              fontFamily: 'Poppins, IBM Plex Sans Arabic, sans-serif',
              textAlign: currentLang.value === 'ar' ? 'right' : 'left',
              direction: 'ltr',
            }}
            rtl={currentLang.value === 'ar'}
          />
          <Router />
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
