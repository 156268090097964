import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, debounce, Dialog, Grid, IconButton, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Loader } from '../../components/Loader';
import PropertyCard from '../../components/PropertyCard';
import useLocales from '../../hooks/useLocales';
import * as propertiesActions from '../../models/properties/actions';
import { selectors as propertiesSelectors } from '../../models/properties/reducers';

const AddPropertiesModal = ({ open, onClose, formik }) => {
  const { translate } = useLocales();
  const properties = useSelector(propertiesSelectors.properties);
  const search = useSelector(propertiesSelectors.search);
  const loading = useSelector(propertiesSelectors.loading);
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState('');
  const [selectedProperties, setSelectedProperties] = useState([]);

  useEffect(() => {
    dispatch(propertiesActions.getPropertiesRequest({ include_archived: 0, included_in_project: false }));
  }, []);

  const onSearch = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    if (value) {
      dispatch(
        propertiesActions.searchPropertiesRequest({
          include_archived: 0,
          included_in_project: false,
          unit_number: value,
        })
      );
    }
  };

  const selectedPropertyIds = (formik?.values?.properties || []).map((p) => p.id);
  let items = searchValue ? search : properties;
  if (items) {
    items = items.filter((p) => !selectedPropertyIds.includes(p.id));
  }

  const handlePropertySelect = (property) => {
    setSelectedProperties((prevSelected) =>
      prevSelected.includes(property) ? prevSelected.filter((p) => p !== property) : [...prevSelected, property]
    );
  };

  const handleAdd = () => {
    formik.setFieldValue('properties', [...(formik.values.properties || []), ...selectedProperties]);
    setSelectedProperties([]);
    onClose();
  };

  return (
    <Dialog open={open} fullWidth>
      <Box sx={{ minHeight: 100, minWidth: 400, p: 2 }}>
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6" paragraph sx={{ mb: 0 }}>
              {translate('project.addUnits')}
            </Typography>
            <IconButton sx={{ p: 0 }} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          <TextField
            sx={{ mb: 2 }}
            fullWidth
            onChange={debounce(onSearch, 200)}
            label={translate('deal.searchByUnitNumber')}
          />
        </Box>
        <Grid container spacing={2} sx={{ maxHeight: 500, overflow: 'auto' }}>
          {loading && <Loader />}
          {items &&
            items.map((p) => (
              <Grid item xs={12} sm={6} key={p.id}>
                <PropertyCard
                  row
                  property={p}
                  cardHeight={'165px'}
                  clickable
                  selected={selectedProperties.includes(p)}
                  onClick={() => handlePropertySelect(p)}
                />
              </Grid>
            ))}
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button
            variant="contained"
            onClick={handleAdd}
            disabled={selectedProperties.length === 0}
            sx={{ color: 'white' }}
          >
            {translate('project.add')} ({selectedProperties.length}){console.log(selectedProperties)}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AddPropertiesModal;
